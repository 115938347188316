import React from 'react';
import Markdown from 'react-markdown';
import { FaFilePdf, FaFileWord, FaFileCsv, FaLink } from 'react-icons/fa';

import './index.css';

function ChatMessage({ content, role, sources }) {

  const renderIcon = (entity) => {
    const lowercasedEntity = entity.toLowerCase();
    if (lowercasedEntity.endsWith('.pdf')) {
      return <FaFilePdf color='red' />;
    } else if (lowercasedEntity.endsWith('.doc') || lowercasedEntity.endsWith('.docx')) {
      return <FaFileWord color='blue' />;
    } else if (lowercasedEntity.endsWith('.csv')) {
      return <FaFileCsv color='green' />;
    } else if (lowercasedEntity.startsWith('http') || lowercasedEntity.startsWith('www')) {
      return <FaLink color='deepskyblue' />;
    } else {
      return null;
    }
  };

  return (
    <div className={`chat-message ${role}`}>

      <div className={`chat-message-content`}>
        <Markdown>{content}</Markdown>
      </div>

      {role === 'assistant' && sources?.length > 0 && (
        <div className="reference-entities">
          <ul>
            {sources.map((entity, idx) => (
              <li key={idx} className="reference-entity">
                {renderIcon(entity)}
                <span>{entity}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

    </div>
  );

}

export default ChatMessage;
