// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-button:hover {
    background-color: #3700b3;
}

.setting-button {
    background-color: black;
    color: white;
    /* margin: 10px; */
    padding: 12px;
    border: none;
    background-color: #000000;
    color: white;
    cursor: pointer;
    border-radius: var(--border-radius);
    box-shadow: 0px 0px 10px #ddd;
}

.setting-button-container {
    position: relative;
    display: inline-block;
}

.popup-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #000811;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 8px 0;
    border-radius: var(--border-radius);
    z-index: 1000;
}

.popup-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.popup-menu li {
    padding: 8px 16px;
    cursor: pointer;
    color: rgb(247, 247, 247);
}

.filter-date-range-picker {
    border-radius: var(--border-radius);
}`, "",{"version":3,"sources":["webpack://./src/components/SettingButton/index.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,mCAAmC;IACnC,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,yBAAyB;IACzB,yCAAyC;IACzC,cAAc;IACd,mCAAmC;IACnC,aAAa;AACjB;;AAEA;IACI,qBAAqB;IACrB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":[".setting-button:hover {\n    background-color: #3700b3;\n}\n\n.setting-button {\n    background-color: black;\n    color: white;\n    /* margin: 10px; */\n    padding: 12px;\n    border: none;\n    background-color: #000000;\n    color: white;\n    cursor: pointer;\n    border-radius: var(--border-radius);\n    box-shadow: 0px 0px 10px #ddd;\n}\n\n.setting-button-container {\n    position: relative;\n    display: inline-block;\n}\n\n.popup-menu {\n    position: absolute;\n    right: 0;\n    top: 100%;\n    background-color: #000811;\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n    padding: 8px 0;\n    border-radius: var(--border-radius);\n    z-index: 1000;\n}\n\n.popup-menu ul {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n}\n\n.popup-menu li {\n    padding: 8px 16px;\n    cursor: pointer;\n    color: rgb(247, 247, 247);\n}\n\n.filter-date-range-picker {\n    border-radius: var(--border-radius);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
