import React from 'react';

import './index.css';

function ChatInput({ content, setContent, handleSubmit }) {
  return (
    <form className="chat-input" onSubmit={handleSubmit}>
      <input
        type="text"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Type your message"
      />
      <button type="submit">Send</button>
    </form>
  );
}

export default ChatInput;
