// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  display: grid;
  grid-template-rows: auto 44px;
  border-top: 1px solid #000000;
  padding-top: 0;
  height: calc(100% - 60px);
}

.chat-messages-wrapper {
  overflow-y: auto;
}

.chat-messages-main {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatMessages/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,6BAA6B;EAC7B,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".chat-container {\n  display: grid;\n  grid-template-rows: auto 44px;\n  border-top: 1px solid #000000;\n  padding-top: 0;\n  height: calc(100% - 60px);\n}\n\n.chat-messages-wrapper {\n  overflow-y: auto;\n}\n\n.chat-messages-main {\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n  padding: 16px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
