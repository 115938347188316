import React, { useEffect, useState } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import "./index.css";

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { FaRegCircleUser } from "react-icons/fa6";
import { DateRangePicker } from 'react-date-range';

import Filesview from '../FilesView';
import Authentication from './Authentication';


const SettingButton = () => {
    const [open, setOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });
    const [displayDateRange, setDisplayDateRange] = useState(false);
    const [dateRangePreview, setDateRangePreview] = useState(false);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setIsAuthenticated(false);
    };

    const handleDateRangeChipClick = () => {
        setDisplayDateRange(!displayDateRange);
    };

    const handleDateRangeChange = (range) => {
        setSelectionRange(range.selection);
        console.log(`Range: ${JSON.stringify(range)}`);
    };

    useEffect(() => {
        const { startDate, endDate } = selectionRange;

        const previewString = `${startDate.toDateString()} - ${endDate.toDateString()}`;
        setDateRangePreview(previewString);
    }, [selectionRange]);

    return (
        <div>
            Web Search: <Switch inputProps={{ 'aria-label': 'Web Search' }} />

            <Box sx={{ display: 'inline-block' }}>
                <Chip color='primary' label={dateRangePreview} onClick={handleDateRangeChipClick} />
                <Box sx={{ position: 'absolute', right: '24px', top: '72px' }}>
                    {displayDateRange && (<DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleDateRangeChange}
                        className='filter-date-range-picker'
                    />)}
                </Box>
            </Box>

            <IconButton color="inherit" onClick={handleClickOpen}>
                <SettingsIcon />
            </IconButton>

            <IconButton color="inherit">
                <FaRegCircleUser />
            </IconButton>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload/Remove Files</DialogTitle>
                <DialogContent>
                    {!isAuthenticated ? (
                        <Authentication setIsAuthenticated={setIsAuthenticated} />
                    ) : (
                        <Filesview />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default SettingButton;
