import { React } from "react";
import "./index.css";
import FileView from "../FileView";

const FilesView = () => {

    return (
        <div>
            <FileView />
        </div>
    );

};

export default FilesView;