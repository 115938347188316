// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-input {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  height: 46px;
}

.chat-input input {
  flex: 1 1;
  font-size: inherit;
  padding-inline: 16px;
  border: none;
  outline: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-dark);
}

.chat-input button {
  padding-inline: 16px;
  font-size: inherit;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-dark);
  background-color: var(--button-background-color);
  transition: filter .2s;
}

.chat-input button:hover {
  filter: brightness(85%);
}`, "",{"version":3,"sources":["webpack://./src/components/ChatMessages/ChatInput/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,aAAa;EACb,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,mCAAmC;EACnC,kCAAkC;EAClC,gDAAgD;EAChD,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".chat-input {\n  display: flex;\n  flex-direction: row;\n  column-gap: 10px;\n  height: 46px;\n}\n\n.chat-input input {\n  flex: 1;\n  font-size: inherit;\n  padding-inline: 16px;\n  border: none;\n  outline: none;\n  border-radius: var(--border-radius);\n  box-shadow: var(--box-shadow-dark);\n}\n\n.chat-input button {\n  padding-inline: 16px;\n  font-size: inherit;\n  color: white;\n  border: none;\n  cursor: pointer;\n  border-radius: var(--border-radius);\n  box-shadow: var(--box-shadow-dark);\n  background-color: var(--button-background-color);\n  transition: filter .2s;\n}\n\n.chat-input button:hover {\n  filter: brightness(85%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
