// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-message {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  max-width: 70%;
}

.chat-message.user {
  align-self: end;
  justify-content: flex-end;
  background-color: #e0e0e0;
}

.chat-message.assistant {
  align-self: start;
  justify-content: flex-start;
  background-color: #f6f6f6;
}

.chat-message-content {
  margin: 0;
  padding-inline: 16px;
}

@media (max-width: 480px) {
  .chat-message {
    max-width: 90%;
  }
}

.chat-message.user .chat-message-content {
  color: rgb(0, 0, 0);
  align-self: flex-end;
}

.chat-message.assistant .chat-message-content {
  align-self: flex-start;
}

.reference-entities ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
  padding: 16px;
}

.reference-entity {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 10px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-medium);
}`, "",{"version":3,"sources":["webpack://./src/components/ChatMessages/ChatMessage/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mCAAmC;EACnC,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;EACjB,mCAAmC;EACnC,oCAAoC;AACtC","sourcesContent":[".chat-message {\n  display: flex;\n  flex-direction: column;\n  border-radius: var(--border-radius);\n  max-width: 70%;\n}\n\n.chat-message.user {\n  align-self: end;\n  justify-content: flex-end;\n  background-color: #e0e0e0;\n}\n\n.chat-message.assistant {\n  align-self: start;\n  justify-content: flex-start;\n  background-color: #f6f6f6;\n}\n\n.chat-message-content {\n  margin: 0;\n  padding-inline: 16px;\n}\n\n@media (max-width: 480px) {\n  .chat-message {\n    max-width: 90%;\n  }\n}\n\n.chat-message.user .chat-message-content {\n  color: rgb(0, 0, 0);\n  align-self: flex-end;\n}\n\n.chat-message.assistant .chat-message-content {\n  align-self: flex-start;\n}\n\n.reference-entities ul {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 10px;\n  margin: 0;\n  padding: 16px;\n}\n\n.reference-entity {\n  display: flex;\n  flex-direction: row;\n  column-gap: 10px;\n  align-items: center;\n  width: fit-content;\n  padding: 6px 10px;\n  border-radius: var(--border-radius);\n  box-shadow: var(--box-shadow-medium);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
