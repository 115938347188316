import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
// import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import "./index.css";

const PASS_KEY = 'ai.sprinklenet.com.creds';

const Authentication = ({ setIsAuthenticated }) => {

  const [showPassword, setShowPassword] = useState(false);
  const [savePassword, setSavePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [errMessage, setErrMessage] = useState('');

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handlePasswordChange = (event) => {
    setErrMessage('');
    setPassword(event.target.value);
  }

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    if (password === 'aisprinklenet') {
      if (savePassword) localStorage.setItem(PASS_KEY, password);
      setErrMessage('');
      setIsAuthenticated(true);
    } else {
      setErrMessage('Incorrect Password');
    }
  };

  useEffect(() => {
    setPassword(localStorage.getItem(PASS_KEY) ?? '');
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
      {/* <DialogContentText>
        Please enter the password to access this section.
      </DialogContentText> */}
      <form style={{ display: 'flex', gap: '10px', paddingTop: '5px' }}>
        <TextField
          autoFocus
          autoComplete="on"
          margin="dense"
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          size="small"
          value={password}
          onChange={handlePasswordChange}
          style={{ margin: '0px' }}
          inputRef={input => input && input.focus()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          type="submit"
          onClick={handlePasswordSubmit}
          color="primary"
          variant="contained"
          style={{ whiteSpace: 'nowrap', padding: '2px 24px' }}
        >
          Submit
        </Button>
      </form>
      <span style={{ color: 'red' }}>{errMessage}</span>
      <FormControlLabel
        label="Save for auto-fill?"
        control={
          <Checkbox
            checked={savePassword}
            onClick={() => setSavePassword(!savePassword)}
          />
        }
      />
    </div>
  );

}

export default Authentication;