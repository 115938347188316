import React, { useEffect, useState } from 'react';
import ChatMessage from '../ChatMessages/ChatMessage';

import './index.css';

function TypingIndicator({ scrollToBottom }) {

    const [text, setText] = useState('Typing');
    const [counter, setCounter] = useState(0);

    // scroll to messages bottom on render of typing indicator
    scrollToBottom();

    useEffect(() => {
        let updatedText = 'Typing';

        for (let i = 0; i <= counter % 3; i++) {
            updatedText += ' .';
        }

        setText(updatedText);

        const timeoutId = setTimeout(() => {
            setCounter(counter + 1);
        }, 500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [counter]);

    return (

        <div id='typing-indicator'>
            <ChatMessage key='999999' content={text} role='assistant' />
        </div>

    )

}

export default TypingIndicator;