import React, { useEffect, useState, useRef } from "react";

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import UrlInput from "./url-input";
import "./index.css";

const FileView = () => {
    const fileInputRef = useRef(null);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        displayFiles();
    }, []);

    const handleFileChange = (event) => {
        setSelectedFiles([...event.target.files]);
    };

    const displayFiles = () => {
        setLoading(true);
        axios.get('https://ai.sprinklenet.com/api/v1/files')
            .then(response => {
                setFiles(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching files:', error);
                setSnackbar({ open: true, message: 'Error fetching files', severity: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = (fileName) => {
        setLoading(true);
        axios.delete(`https://ai.sprinklenet.com/api/v1/files`, { data: { name: fileName } })
            .then(response => {
                setFiles(files.filter(file => file !== fileName));
                setLoading(false);
                setSnackbar({ open: true, message: 'File deleted successfully', severity: 'success' });
            })
            .catch(error => {
                console.error('Error deleting file:', error);
                setLoading(false);
                setSnackbar({ open: true, message: 'Error deleting file', severity: 'error' });
            });
    };

    const uploadFiles = (e) => {
        e.preventDefault();
        if (selectedFiles.length === 0) {
            setSnackbar({ open: true, message: 'Please select files to upload', severity: 'error' });
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('file', file);
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://ai.sprinklenet.com/api/v1/files',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        };

        setLoading(true);
        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setSnackbar({ open: true, message: 'Files uploaded and trained successfully', severity: 'success' });
                setSelectedFiles([]);
                document.getElementById('file-input').value = null; // Clear the file input
                // Optionally, we can refresh the file list after upload
                displayFiles();
            })
            .catch((error) => {
                console.error('Error uploading files:', error);
                setSnackbar({ open: true, message: 'Error uploading files', severity: 'error' });
            }).finally(() => {
                setLoading(false);
            });
    };

    const removeFilesSelection = (e) => {
        e.preventDefault();
        setSelectedFiles([]);
        fileInputRef.current.value = null;
    }

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: 'success' });
    };

    return (
        <div style={{ pointerEvents: loading ? 'none' : 'auto' }}>
            <UrlInput displayFiles={displayFiles}></UrlInput>
            <span style={{ display: 'block', textAlign: 'center', marginBlock: '10px' }}>or</span>
            <label htmlFor="file-input" className={"drop-container " + (selectedFiles.length > 0 ? "selected" : "")}>
                <span className="drop-title">Drop files here</span>
                or
                <input id="file-input" className="input" type="file" ref={fileInputRef} onChange={handleFileChange} accept=".pdf,.txt,.docs,.doc,.csv,.xls,.xlsx" multiple />
                <div className="file-input-action-buttons">
                    {selectedFiles.length > 0 && <Button class="dialog-button" onClick={removeFilesSelection}>Clear</Button>}
                    {selectedFiles.length > 0 && <Button class="dialog-button" onClick={uploadFiles}>Upload</Button>}
                </div>
            </label>
            {
                loading &&
                <div className="files-loading-spinner">
                    <CircularProgress style={{ margin: '20px' }} />
                </div>
            }
            {
                files.length > 0 && !loading &&
                <div className="uploaded-files-container">
                    <span className="uploaded-files-header">Uploaded files</span>
                    <List className="uploaded-files-list">
                        {files.map((file, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={file} primaryTypographyProps={{
                                    variant: 'subtitle2',
                                    style: {
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }
                                }} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(file)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </div>
            }
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div >
    );
};

export default FileView;
